import React from "react";
import Seo from "../components/Layout/Seo";
import Site from "../components/Layout/Site";

const Careers = () => {
  return (
    <Site>
      <Seo title="Careers" />
    </Site>
  );
};

export default Careers;
